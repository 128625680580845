#activation_account{ background: #0f0 none repeat scroll 0 0; color: #000; font-size: 15px; font-weight: bold; padding: 10px 0; text-align: center;}

.navbar-inverse{ background-color: transparent; border-color: transparent; }
	#logo{ width: auto; max-width: 211px; float: left; }
	#logo img{height: auto;max-width: none;}
	.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:focus, .navbar-inverse .navbar-nav > .active > a:hover{ background-color: transparent; color: #000; }
	.navbar-inverse .navbar-nav > li > a:focus, .navbar-inverse .navbar-nav > li > a:hover{ color: #000; }
	/* .navbar-header{ padding-bottom: 15px; } */
	.navbar-inverse .navbar-toggle .icon-bar{ background-color: #333; }
		.navbar-inverse .navbar-toggle .icon-bar:hover{ background-color: #fff; }
		
	.navbar-nav{ float: right; }
		
	.navbar-inverse .navbar-toggle:focus .icon-bar, .navbar-inverse .navbar-toggle:hover .icon-bar{ background-color: #fff; }
	
	.navbar{ margin-bottom: 0; }
	
#dashboard_content{ padding: 40px 0;}

	.page-header{ margin-top: 10px; font-size: 30px; }

	#cc_area{ padding-top: 5px; }
		#setting_cc{ width: 25px; }
	
	
#grp_setting_top{ border-bottom: 1px solid #ddd; padding-bottom: 10px; }

.actions a {font-weight: bold; margin-bottom: 5px; margin-right: 5px; }
	

@media only screen and ( max-width: 767px ) {
	.navbar-nav{ float: none; }
}
@media ( max-width: 1000px ) {
	#d_nav{ margin-top: 25px !important; }
} 
@media all and (max-width: 240px) {
	#toast-container > div {
		padding: 8px 8px 8px 50px;
		width: 108px;
	}
}
@media all and (min-width: 241px) and (max-width: 320px) {
	#toast-container > div {
		padding: 8px 8px 8px 50px;
		width: 128px;
	}
}
@media all and (min-width: 321px) and (max-width: 480px) {
	#toast-container > div {
		padding: 8px 8px 8px 50px;
		width: 192px;
	}
}
@media all and (min-width: 481px) and (max-width: 768px) {
	#toast-container > div {
		padding: 15px 15px 15px 50px;
		width: 300px;
	}
}
@media all and (min-width: 769px) {
	#toast-container > div {
		padding: 15px 15px 15px 50px;
		width: 300px;
	}
}
@media all and (max-width: 768px) {
	#toast-container > div {
		padding: 15px 15px 15px 50px!important;
		min-width: 300px!important;
		width: 100%!important;
	}
}